<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        The figure below shows the valence bond interpretation of the bonding in ethylene,
        <stemble-latex content="$\ce{C2H4}.$" />
        Determine the type of
        <span class="text-bold">orbital/bond</span> indicated by each of the labels in the figure
        below.
      </p>

      <p>
        <img
          src="/img/assignments/etheneVBT.png"
          alt="Ethene Valence Bond Theory"
          width="390px"
          class="mb-3 ml-10"
        />
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <th style="font-size: 15px">Label</th>
            <th
              v-for="{label, value} in orbitalTypes"
              :key="`label-${value}`"
              style="font-size: 13px"
            >
              <stemble-latex :content="label" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(atomLabel, i) in labels" :key="atomLabel">
            <td>
              <stemble-latex :content="`$\\text{${atomLabel}}$`" />
            </td>
            <td v-for="{value} in orbitalTypes" :key="value" style="text-align: center">
              <v-radio-group v-model="inputs[`input${i + 1}`]" :disabled="!allowEditing">
                <v-radio :value="value" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question256',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
      },
      labels: ['A', 'B', 'C', 'D', 'E', 'F'],
      orbitalTypes: [
        {label: '$\\text{s}$', value: 's'},
        {label: '$\\text{p}$', value: 'p'},
        {label: '$\\text{d}$', value: 'd'},
        {label: '$\\text{sp}$', value: 'sp'},
        {label: '$\\text{sp}^2$', value: 'sp2'},
        {label: '$\\text{sp}^3$', value: 'sp3'},
        {label: '$\\sigma$', value: 'sigma'},
        {label: '$\\pi$', value: 'pi'},
        {label: '$\\delta$', value: 'delta'},
      ],
    };
  },
};
</script>
